.locations-side-panel {
  position: fixed;
  left: 5%;
  width: 8vw;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2%;
  padding: 1%;
}

.efficiency-container {
  width: 80vw;
}
