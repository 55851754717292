.dashboard-barchart-canvas {
  width: 70vw;
  margin-top: 1%;
}

.dahsboard-barchart-tooltip {
  position: absolute;
  text-align: left;
  padding: 10% 5% 10% 5%;
  font: bold 12px sans-serif;
  color: #334bff;
  background-color: white;
  border: solid 1px #aaa;
  border-radius: 8px;
}
