.single-date-table > tbody > tr:nth-last-child(2) > td {
  border: none;
}

.single-date-table > tbody > tr:last-child > td.average-efficiency-row-item {
  border-top: 1px solid #334bff;
}

.single-date-table > tbody > tr:last-child > td.average-efficiency-row-item.first-item {
  font-weight: bold;
  white-space: nowrap;
  text-align: end;
}
