/*
* CSS for delete icon
*/
.delete-icon {
  cursor: pointer;
  align-self: center;
}

.barcode-table-header {
  margin-top: 2em;
}

/*
* CSS for the session total container
*/
.session-total-container {
  margin-top: 2em;
}

.session-total-container > p {
  display: inline-block;
  color: #252734;
}

.session-total-container > p:last-child {
  margin-left: 2.5%;
}

/*
* This is the css for the add row button
*/
.barcode-table-add-row-button {
  align-self: flex-start;
  margin-top: 2.5%;
}

/**
* This is the CSS for the submit button
*/
.barcode-table-submit-button {
  width: 50%;
  margin-top: 2em;
}
