#order-details-form {
  display: flex;
  flex-direction: column;
  margin-top: 3em;
  width: 50%;
  align-items: center;
  justify-content: space-between;
}

#order-details-form > * {
  margin-top: 2.5%;
}
