/**
* Item code header styling
*/
.item-code-header > h3 {
  color: #334bff;
  text-decoration: underline;
}

.header-hr-border {
  width: 65%;
  border: 1px solid #e5e5e5;
}

/**
* Header styling
*/
.ordered-received-stock-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 40%;
}

.ordered-received-stock-subheader {
  text-align: center;
}

.ordered-received-stock-subheader > h3 {
  color: #334bff;
}

#received {
  margin-top: 2.5%;
}

/**
* Transition styling for the dropdown icon
*/
.accordion-trigger[aria-expanded='true'] img[src*="/down-chevron.svg"] {
  transform: rotate(-180deg);
}

/**
* Styling for the dropdown icon along with the number within the Accordion Button
*/
.accordion-dropdown-number {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 55%;
  height: 100%;
}

.accordion-dropdown-number > * {
  margin-left: 2.5%;
}

.accordion-dropdown-number img[src*="/tick-mark.svg"] {
  width: 6%;
  height: 6%;
}

.accordion-dropdown-number img[src*="/down-chevron.svg"] { /* stylelint-disable-line */
  width: 6%;
  height: 6%;
}

.accordion-dropdown-number img[src*="/orange-down-arrow.svg"],
.accordion-dropdown-number img[src*="/red-up-arrow.svg"] {
  width: 40%;
  height: 40%;
}

/* Specific styling for the adjustment dropdown button */
.accordion-dropdown-number > .accordion-adjustment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

/**
* Styling for the accordion panel that is dropped down from the button
*/
.accordion-panel {
  margin-bottom: 2.5%;
}

.accordion-panel > .accordion-header > p {
  color: #334bff;
}

.accordion-panel > * {
  border-bottom: 1px solid rgb(223, 223, 223);
}

.accordion-panel .accordion-item {
  background-color: white;
}

.accordion-panel[hidden] {
  display: none;
}

/**
* Styling for the divider in the adjustment container
*/
.adjustment-divider {
  margin-left: 2.5%;
  margin-right: 2.5%;
  height: calc(1.85vw);
}
