#upload-tech-pack-form {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  align-items: center;
  justify-content: space-between;
}

#upload-tech-pack-form > * {
  margin-top: 2.5%;
}
