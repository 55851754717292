.search-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.search-bar > input {
  width: 75%;
  text-align: center;
  background-color: transparent;
  font-family: Poppins; /* stylelint-disable-line */
}

.search-btn {
  color: #334bff;
  font-family: Poppins; /* stylelint-disable-line */
  font-size: 0.9em;
  border-radius: 4px;
  border: 0.5px solid #334bff;
  background-color: transparent;
  transition: all 0.2s ease-in;
  margin-left: 0.5%;
}

.search-btn:hover {
  cursor: pointer;
  background-color: #334bff;
  border: 0.5px solid black;
  color: white;
}

/* Styling the error message  */
label[for='search-input'] > span.error {
  color: red;
  font-family: Poppins; /* stylelint-disable-line */
  font-size: 0.8em;
}

label[for='search-input'] > span.hide {
  display: none;
}

label[for='search-input'] > span.show {
  display: block;
}

label[for='search-input'] > span.error > p {
  margin: 0;
}

/* Styling for the search icon */
.search-icon {
  width: 5%;
  height: 5%;
  margin-left: 2.5%;
  cursor: pointer;
}
