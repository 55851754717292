.container {
  padding: 0 5% 0 5%;
}

/* Start styling for navigation menu bar */

a {
  text-decoration: none;
  color: black;
  transition: all 0.2s ease-in;
}

a:hover {
  color: #334bff;
}

a.selected {
  color: #334bff;
}

li {
  cursor: pointer;
}

nav ul {
  list-style-type: none;
  text-align: right;
}

nav > ul > li {
  display: inline;
  margin-left: 2%;
  font-size: 1.25em;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #252734;
}

nav > h2 {
  font-size: 1.5em;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #334bff;
  float: left;
  cursor: pointer;
}

/* End styling for navigation menu bar */

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5em;
}

/* Styling for print screens. This is used when printing barcodes */

@media print {
  nav {
    display: none;
  }

  .container {
    padding: 0;
  }

  .App {
    margin-top: 0;
    display: block;
  }
}

/* This is a catch all style for font resizing on smaller screens. */

@media screen and (max-width: 475px) {
  body {
    font-size: 2vw;
  }

  button {
    font-size: 2vw;
  }
}

/* Styling for the products navbar dropdown */

.products-dropdown {
  position: relative;
  display: inline-block;
}

.products-header:hover {
  color: #334bff;
}

.products-dropdown-content {
  display: none;
  position: fixed;
  background-color: transparent;
  text-align: center;
  width: max-content;
  z-index: 1;
}

.products-dropdown-content a {
  color: black;
  display: block;
}

.products-dropdown-content a:hover,
a.selected {
  color: #334bff;
}

.products-dropdown:hover .products-dropdown-content {
  display: block;
}
