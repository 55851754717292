#form {
  display: flex;
  flex-direction: column;
  width: 500px;
}

#form > * {
  margin-top: 15px;
}

#form span.valid {
  margin-left: 15px;
}

#form span.invalid {
  margin-left: 15px;
}
