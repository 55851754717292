.search-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2%;
  padding: 1%;
}

.search-result-card {
  width: 85%;
  text-align: center;
  cursor: pointer;
  border: 1px solid #334bff;
  border-radius: 5px;
  margin-top: 2%;
  transition: transform 0.2s ease;
}

.search-result-card:hover {
  transform: scale(1.2);
}

.search-result-card > p {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Poppins; /* stylelint-disable-line */
  font-size: 0.9em;
}
