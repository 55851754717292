.rfid_download_container {
  width: 75vw;
}

.location_search_container {
  display: flex;
  margin-left: 25%;
  width: 35vw;
  justify-content: space-evenly;
}

.filter-date-picker {
  width: 10vw;
  z-index: 2;
}

.date_picker {
  width: 25vw;
  position: absolute;
  left: 75%;
}

.rfid_download_result_table {
  margin-top: 4%;
}

.download-rfid-data-date-picker {
  display: flex;
  justify-content: end;
}

.download-rfid-data-date-picker > .react-datepicker-wrapper {
  width: 25%;
}
