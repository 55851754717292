.svg_barcode_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.barcode-container {
  max-width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

p.barcode-text-container {
  display: inline-block;
  font-size: 0.6em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-align: center;
  margin-top: 0;
  margin-bottom: 8px;
  min-height: 22px;
}

.barcode-print-btn {
  margin-top: 5%;
  margin-bottom: 2.5%;
  width: 80%;
}

@media print {
  .svg_barcode_container {
    justify-content: space-between;
  }

  .back_arrow,
  .barcode-print-btn {
    display: none;
  }
}
