/**
* Styling for the minusing register header block
*/

.minusing-register-header-block {
  color: #334bff;
  border: 1px solid #334bff;
  padding-left: 2.5%;
  padding-right: 2.5%;
  margin-top: 3.5%;
  background-color: #fff;
}

.minusing-register-container {
  background-color: #fff;
  width: 100%;
  margin-top: 5%;
}

.minusing-register-header {
  position: sticky;
  top: 0;
  background-color: #d3d3d3;
  border-width: 1px 0 1px 0;
  border-color: black;
  border-style: solid;
  font-size: 0.85rem;
}

@media screen and (max-width: 900px) {
  .table-number {
    display: none;
  }
}

/**
* Styling for all the different rows of the minusing register
*/

.minusing-register-header > h3 {
  color: #334bff;
}

.minusing-register-row.first-row {
  color: #19d268;
}

.minusing-register-row p.index {
  font-size: 0.9em;
}

.minusing-register-row p.date {
  font-size: 0.9em;
  margin-block-end: 0;
}

.minusing-register-row.minusing-quantity {
  color: #ff333e;
}

.minusing-register-row.minusing-quantity-actual {
  margin-bottom: 40%;
  color: #b3242f;
}

.minusing-register-row.size-total-cut {
  color: #ff333e;
  font-weight: bold;
}

.minusing-register-row.size-total-cut-actual {
  color: #b3242f;
  font-weight: bold;
}

.minusing-register-remaining-row {
  color: #2126ff;
}

.minusing-register-remaining-row-actual {
  color: #ff9500;
}
