.dhu-dashboard-container {
  height: 80vh;
  width: 80vw;
}

.filter-buttons {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.dhu-dashboard-date-picker {
  display: flex;
  justify-content: end;
}

.dhu-dashboard-date-picker > .react-datepicker-wrapper {
  width: 25%;
}

.dhu-dynamic-dashboard-component {
  margin-top: 2%;
}

.dhu-dashboard-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #334bff;
}

.dhu-dashboard-error {
  margin-top: 5%;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #334bff;
}

.dhu-dashboard-container-base {
  margin: 3% 0 0 0%;
  width: 70vw;
}

/** Hide scroll bar */
::-webkit-scrollbar {
  display: none;
}
