.sewing-dashboard-date-picker {
  display: flex;
  justify-content: end;
}

.sewing-dashboard-date-picker > .react-datepicker-wrapper {
  width: 25%;
}

.sewing-dashboard-error {
  margin-top: 3%;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #334bff;
}
